<template>
  <header
    v-if="rdy"
    class="navbar navbar-header navbar-header-fixed wd-100p"
    style="position: fixed; z-index: 99; background: rgb(51 86 113)"
  >
    <a id="mainMenuOpen" class="burger-menu">
      <!-- <img src="http://www.ubtraffic.mn/assets/img/icon.png" /> -->
    </a>
    <div class="navbar-brand">
      <!-- <a href="/" class="d-none d-lg-block">Зам, тээврийн хөгжлийн төв<span></span></a> -->
      <a href="/" class="d-flex align-items-center">
        <img
          src="/logo.png"
          alt=""
          title=""
          style="background: white; border-radius: 40px; max-height: 40px"
          class="mg-r-10"
        />
        <span class="d-none d-lg-block text-white"
          >Зам, тээврийн хөгжлийн төв</span
        >
      </a>
    </div>
    <!-- navbar-brand -->

    <div class="navbar-right">
      <ul class="nav text-uppercase font-weight-bolder align-items-center">
        <li class="nav-item">
          <a
            @click="mdlContact = true"
            class="text-white"
            href="javascript:void(0)"
            >утас: 7000-2413</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="`text-white nav-link ${
              $route.name == 'home' ? 'active' : ''
            }`"
            href="/"
            >Эхлэл</a
          >
        </li>
      </ul>
    </div>
  </header>
  <router-view />
  <div
    v-if="mdlContact"
    class="modal fade show"
    style="display: block; padding-right: 17px"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tx-14">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel3">Холбоо барих</h6>
          <button
            @click="mdlContact = false"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body" style="overflow-y: auto; text-align: left">
          <h3>
            <a href="tel: 70002413">7000-2413</a>
          </h3>

          <div class="tx-16">
            Зам тээврийн хөгжлийн төв ТӨҮГ, Төлбөр авах үйл ажиллагааны хэлтэс
          </div>
          <hr />
          <h3>
            <a href="mailto: roadpay@rtdc.gov.mn">roadpay@rtdc.gov.mn</a>
          </h3>
          <div class="tx-16">И-мэйл</div>
          <hr />
          <h4>Mанай хаяг</h4>
          <div class="tx-16">
            Зам тээврийн хөгжлийн төв ТӨҮГ 16100 Улаанбаатар хот, Баянгол
            дүүрэг, 29 дугаар хороо Төлбөр авах үйл ажиллагааны хэлтэс
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="mdlContact"
    class="modal-backdrop fade show"
    @click="mdlContact = false"
  ></div>
  <template v-if="$store.state.loading">
    <div class="dynamic-locker">
      <div class="locker-loader">
        <div class="locker-main" style="min-width: 140px">
          Түр хүлээнэ үү...
        </div>
      </div>
    </div>
  </template>
  
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      mdlContact: false,
      rdy: false,
    };
  },
  mounted() {
    console.log("this.$route.name", this.$route.name);
    if (this.$route.name != undefined && this.$route.name != "home") {
      this.rdy = true;
    }
  },
  watch: {
    "$route.name": {
      handler: function () {
        if (this.$route.name != undefined && this.$route.name != "home") {
          this.rdy = true;
        } else {
          this.rdy = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["handleLoading", "handleLogOut"]),
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    goToLogout() {
      this.$router.push({ name: "profile" });
      // this.handleLoading({ isLoading: true });
      // this.handleLogOut({ store: this.$store }).then((res) => {
      //   if (res != false) {
      //     this.handleLoading({ isLoading: false });
      //     this.$router.push("/");
      //   }
      // });
    },
  },
};
</script>
<style lang="postcss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.dynamic-app .locker-loader {
  display: none;
}
.dynamic-app .dynamic-locker .locker-loader {
  display: block;
}
.locker-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 100%, 0.5) !important;
  backdrop-filter: blur(1px);
  z-index: 9999;
  top: 0;
  left: 0;
  text-align: center;
}
.locker-main {
  color: white;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  width: 10vw;
  margin: 0 auto;
  background: orange;
}

@media (min-width: 120px) {
  .navbar-header .navbar-right {
    min-width: 280px;
  }
}

body {
  font-family: "Roboto", Arial !important;
}
.dynamic-app .locker-loader {
  display: none;
}
.dynamic-app.dynamic-locker .locker-loader {
  display: block;
}
.locker-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 100%, 0.5) !important;
  backdrop-filter: blur(1px);
  z-index: 9999;
  top: 0;
  left: 0;
  text-align: center;
}
.locker-main {
  color: white;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  width: 10vw;
  margin: 0 auto;
  background: orange;
}
</style>
