import Axios from "axios";

import baseURL from "../../config/url";

import Cookies from "js-cookie";
import {
  TOKEN_KEY
} from "@/libs/util";
// import {Notification} from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
//import  store  from '../store/module/app'

class httpRequest {
  constructor() {
    this.options = {
      method: "",
      url: ""
    };
    this.queue = {};
  }
  // showLoading(){
  //   //console.log("show duudsan")
  //   $(".ivu-btn:not(.axiosdis)").attr("disabled", true);
  //   $(".ivu-dropdown-item:not(.axiosdis)").addClass("ivu-dropdown-item-disabled");
  //   LoadingBar.start()
  // }
  // hideLoading(){
  //   //console.log("hide duudsan")
  //   $(".ivu-btn:not(.axiosdis)").attr("disabled", false);
  //   $(".ivu-dropdown-item:not(.axiosdis)").removeClass("ivu-dropdown-item-disabled");
  //   LoadingBar.finish()
  // }
  destroy(url) {
    delete this.queue[url];
    const queue = Object.keys(this.queue);
    return queue.length;
  }
  interceptors(instance, url) {
    //let self= this;
    instance.interceptors.request.use(
      config => {
        if (!config.url.includes("/users")) {
          config.headers["Authorization"] = "Bearer " + Cookies.get(TOKEN_KEY);
        }


        //console.log()

        //store.state.globalSpin = true;
        // this.showLoading();

        // Spin.show()
        return config;
      },
      error => {
        //this.hideLoading();

        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      res => {
        let {
          data
        } = res;

        const is = this.destroy(url);
        if (!is) {
          setTimeout(() => {
            //console.log('Spin.hide')
            //store.state.globalSpin = false;
            // this.hideLoading()
            // Spin.hide()
          }, 500);
        } else {
          // this.hideLoading()
        }

        //console.log(data);
        if (data.code == 200) {
          return data;
        }
        //else return false;
        else if (data.code !== 200) {
          //return data;
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 3000,          
        //     type: "info",
        //     position: 'top-right',
        //     offset: 50
        //   });

          return false;
          // if (data.code === 401) {
          //   return data;
          // } else if (data.code === 204) {
          //   return data;
          // } else if (data.code === 201) {
          //   //console.log("there");
          //   return data;
          // } else if (data.code === 202) {
          //   return data;
          // } else if (data.code === 203) {
          //   return data;
          // } else if (data.code === 503) {
          //   return data;
          // } else if (data.code === 500) {
          //   return data;
          // }
          // if (data.msg) Notice.error({
          //     title: 'Алдаа',
          //     desc: data.msg,
          //     duration:20
          // });

          // this.hideLoading();
          //else return false;
        }
        else
          return false;
      },
      error => {
        // this.hideLoading();
        //console.log("hide duudsan")
        //Message.error('Ачаалж байна.')

        return Promise.reject(error);
      }
    );
  }

  create() {

    let conf = {
      baseURL: baseURL,
      // timeout: 2000,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-URL-PATH": location.pathname
      }
    };
    return Axios.create(conf);
  }

  // mergeReqest (instances = []) {
  //   //
  // }

  request(options) {
    var instance = this.create();
    this.interceptors(instance, options.url);
    options = Object.assign({}, options);
    this.queue[options.url] = instance;
    return instance(options);
  }
}
export default httpRequest;