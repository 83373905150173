import config from "@/config";
var signalR = window.signalR;
import Cookies from "js-cookie";
import { TOKEN_KEY } from "@/libs/util";
// import { Notification } from "element-ui";
import { request } from "@/api/all";
import appState from "./app";

export default {
  state: {
    osrCon: "",
    team: {
      notSeenConversationCount: 0
    },
    users: [],
  },
  mutations: {
    setNotSeenCount(state, count) {
      //console.log('hder duudav', count);
      state.team.notSeenConversationCount = count;
    },
    setOsrCon(state, osrCon) {
      //console.log(id);
      state.osrCon = osrCon;
    },
    setUsers(state, users){
      state.users= users;
    },
  },
  actions: {
    handleSignalR({ commit, state }, { store }) {
      //console.log('state=> ', appState);
      // let _hubBaseUrl = "https://rtdc.gosmart.mn/core/";
      let _hubBaseUrl = "https://roadpay.mn/core3/";
      //console.log('_hubBaseUrl', _hubBaseUrl);

      return new Promise(resolve => {
        let osrCon = new signalR.HubConnectionBuilder()
          .withUrl(_hubBaseUrl + "mainHub", {
            accessTokenFactory: () => Cookies.get(TOKEN_KEY)
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        osrCon
          .start()
          .then(function() {
            //TODO: Golomt-nh gargahgui baiy geher n commentolchihloo. hervee gargaval golomt != gej gargah bairlaliin huvid bottom left yumu right bvl zugeer bh
            // Notification.info({
            //     message: "Амжилттай холбогдлоо.",
            //     duration: 5000,
            //     type: "info",
            //     position: 'top-right',
            //     offset: 50
            // });
            commit("setOsrCon", osrCon);

            osrCon.on("recieveTeamNotification", (teamId) => {
              //console.log('step 97', teamId, store, state.team.notSeenConversationCount);
              commit(
                "setNotSeenCount",
                state.team.notSeenConversationCount + 1
              );
            });

            osrCon.on("v2recieveTeamMessage", () => {
              //console.log('v2recieveTeamMessage');
              commit(
                "setNotSeenCount",
                state.team.notSeenConversationCount + 1
              );
            });

            osrCon.on("receiveGLobalNotification", e => {
              if (e.notification.type == 3) {
                window.location.href = e.notification.link;
                //Notification.show
               // console.log('Notification orluulav', e.notification.msg);
                // Notification.info({
                //   message: e.notification.msg,
                //   duration: e.notification.timeout * 1000,
                //   type: "info",
                //   position: "top-right",
                //   offset: 50
                // });
              }
              //commit("setNotSeenCount", state.team.notSeenConversationCount + 1);
            });

            osrCon.on("getOnlineUsers", users => {
              $.each(users, function(key, value) {
                //console.log('value', value);
                var pos = state.users.map(function(e) { return e.un.toLowerCase(); }).indexOf(value.userName.toLowerCase());
  
                if (pos > -1) {
                  state.users[pos].isOnline = "b";
                }
              });
              //console.log('getOnlineUsers', e);
            });

            osrCon.on("setOnline", name => {
              //console.log('setOnline', name, state.users);
              var userItemArr = state.users.filter( x => x.f03.toLowerCase() == name.toLowerCase() );
              if (userItemArr.length > 0) {
                //console.log('userItemArr[0]', userItemArr[0]);
                setTimeout(function() {
                  userItemArr[0].isOnline = "b";
                }, 10000);
              }
            });

            osrCon.on("onDisconnected", user => {
              //console.log('onDisconnected', user);
              var pos = state.users.map(function(e) { return e.f03.toLowerCase(); }).indexOf(user.toLowerCase());

              if (pos > -1) {
                if (state.users[pos].f03.toLowerCase() == user.toLowerCase()) {
                  state.users[pos].isOnline = "c";

                  //self.sortUsers();
                  //$("i.goChat_uStat[data-userid='" + name.toLowerCase() + "']").removeClass("on").addClass("off");
                }
              }
            });

            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    handleTeamNotSeenCount({ commit }, count) {
      return new Promise((resolve, reject) => {
        try {
          //console.log('handled 1', count);
          commit("setNotSeenCount", count);
          resolve(count);
        } catch (error) {
          reject(error);
        }
      });
    },
    handleGroupUsers({ commit }, { store }) {
      return new Promise(resolve => {        
        request({
          api: { method: "GET", url: "messenger/getGroupUsersFR" },
          store: store
        }).then(res => {
          //console.log('res', res);
          if (res.code == 200) {
            commit("setUsers", res.data.users);
            resolve(res);
            //console.log('getGroupUsersF', store);
            store.state.signalr.osrCon.invoke("readyToGetUsers").catch(err => console.error(err.toString()));

            
          }else{
            resolve(res);
          }
        });
      });
    },
  }
};
