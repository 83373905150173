import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";

import { hasOneOf } from "@/libs/tools";
import { getToken, setToken, setRedirectUrl, canTurnTo, findRouteNameByPath } from "@/libs/util";

const externalPages = [
  "reg",
  "register",
  "login",
  "home",
  "vehicle",
  "detail",
  "pay",
  "regCheck",
  "regList"
];

const routes = [
  {
    path: '/reg/:id',
    name: 'reg',
    component: Home
  }, 
  {
      path: '/',
      name: 'home',
      component: function () {
        return import('../views/home/')
      }
  }, 
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/auth/login')
    }
  }, {
    path: '/profile',
    name: 'profile',
    meta: {
      login: true
    },
    component: function () {
      return import('../views/auth/profile')
    }
  }, {
    path: '/v/:plateNo/:pay',
    name: 'vehicle',
    component: function () {
      return import('../views/vehicle')
    }
  }, {
    path: '/d/:id',
    name: 'detail',
    component: function () {
      return import('../views/dtl')
    }
  }, {
    path: '/apidoc/',
    name: 'apidoc',
    component: function () {
      return import('../views/apidoc/')
    }
  }, {
    path: '/p/:id',
    name: 'penalty',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Detail.vue')
    }
  }, {
    path: '/y/:id',
    name: 'pay',
    component: function () {
      return import(/* webpackChunkName: "pay" */ '../views/Pay.vue')
    }
  }, {
    path: '/a/:id',
    name: 'regCheck',
    component: function () {
      return import(/* webpackChunkName: "pay" */ '../views/regNoCheck.vue')
    }
  }, {
    path: '/l/:id',
    name: 'regList',
    component: function () {
      return import(/* webpackChunkName: "pay" */ '../views/regNoList.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  //mode: 'history',
  routes
})

const turnTo = (to, access, next, withParam) => {
  store.dispatch("handleLoading", { isLoading: false });

  // if (canTurnTo(to.name, access, routes)) {
    if (withParam != undefined && withParam)
      next({
        name: to.name,
        params: to.params
      });
    else {      
      next();
    }
  // } else {
  //   next({ replace: true, name: "permissionDenied" });
  // }
};

const turnLogin = (to, next, withParam) => {
  store.dispatch("handleLoading", { isLoading: false });
  
  setToken("");
  if (withParam != undefined && withParam)
  {
    if(to.path!=undefined)
      setRedirectUrl(to.path);
    next({
      name: "login"
    });
  }
  else {
    next();}
};

router.beforeEach((to, from, next) => {
  //console.log("beaforeEach:", to);
  store.dispatch("handleLoading", { isLoading: true });
  const token = getToken();

  if (!token) {
    //console.log('tokengui 0');
    if (hasOneOf(externalPages, [to.name])) {
      //console.log('tokengui 1');
      store.dispatch("handleLoading", { isLoading: false });
      next();
    } else if ("login" == to.name) {
      //console.log('tokengui 2');
      turnLogin(to, next);
    } else {
      //console.log('tokengui 3');
      turnLogin(to, next, true);
    }
  } else {
    let toPath = findRouteNameByPath(routes, to.path);

    //console.log('tokentoi 0', to);
    if (to.matched.length == 0) { 
      //console.log('tokentoi 1');
    } else if (to.meta!=undefined && to.meta.login) {

      //console.log('tokentoi 2');
      if (store.state.user.userId != "") {
        //console.log('tokentoi 2.1');
        turnTo(to, store.state.user.access, next);
      }else{
        //console.log('tokentoi 2.2');
        store.dispatch("handleUserInfo", { store: store }).then(user => {
          //console.log('tokentoi 2.2.0');
          if (user != false) {
            if (user.data.user_id == "") {
              turnLogin(to, next, true);
            }else{
              store.dispatch("handleSignalR", {})
                .then(() => {
                  turnTo( toPath, store.state.user.access, next, true );
                })
                .catch(() => {
                  turnTo( toPath, store.state.user.access, next, true );
                });
            }            
          } else {
            //console.log('tokentoi 2.2.2');
            turnLogin(to, next, true);
          }
        });
      }
    }else{
      //console.log('tokentoi 3');
      if (hasOneOf(externalPages, [to.name])) {
        //console.log('tokentoi 3.1');
        store.dispatch("handleUserInfo", { store: store }).then(user => {
          if (user != false) {
            if (user.data.user_id == "") {
              turnLogin(to, next, true);
            }else{
              store.dispatch("handleSignalR", {})
                .then(() => {
                  next();
                })
                .catch(() => {
                  turnTo( toPath, store.state.user.access, next, true );
                });
            }            
          } else {
            //console.log('tokentoi 2.2.2');
            turnLogin(to, next, true);
          }
        });
      } else {
        //console.log('tokentoi 3.2');
        turnLogin(to, next, true);
      }
    }
  }

});

router.afterEach(() => {
  store.dispatch("handleLoading", { isLoading: false });
  window.scrollTo(0, 0);
});

export default router
