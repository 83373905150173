import { requestApi } from "@/api/core";
import { dynamicDispatcher } from "@/libs/util";

export const jwt = ({ data, params, store }) => {
  return new Promise(resolve => {
    requestApi({
      api: "jwt",
      method: "post",
      params: params,
      data: data
    }).then(res => {
      if (res != false) {
        let rs = dynamicDispatcher(res, store);
        if (rs != false) {
          resolve(rs);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  });
};

export const getGroups = ({ data, params, store }) => {
  return new Promise(resolve => {
    requestApi({
      api: "Group/getGroups",
      method: "get",
      params: params,
      data: data
    }).then(res => {
      if (res != false) {
        let rs = dynamicDispatcher(res, store);
        if (rs != false) {
          resolve(rs);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  });
};

export const activeKeys = ({ data, params, store }) => {
  return new Promise(resolve => {
    requestApi({
      api: "activeKeys",
      method: "get",
      params: params,
      data: data
    }).then(res => {
      if (res != false) {
        let rs = dynamicDispatcher(res, store);
        if (rs != false) {
          resolve(rs);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  });
};
