import { loginApi, getUserInfo, getPersonalSettings } from "@/api/core";
import { jwt } from "@/api/app";
import { setToken, getToken, offlineCount } from "@/libs/util";

export default {
  state: {
    userId: "",
    access: [],
    userInfo: {
      avator: ""
    },
    personalSettings: [],
    token: getToken(),
    defaultTasks: {},
    //offlineAttaches: offlineCount()
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setToken(token);
    },
    setUserId(state, id) {
      //console.log('setUserId', id);
      state.userId = id;
    },
    setUserInfo(state, info) {
      state.access = info.access;
      state.userInfo = info;
    },
    setOfflineAttaches(state) {
      state.offlineAttaches = offlineCount();
    },
    setAvator(state, avator) {
      state.userInfo.avator = avator;
    },
    setPersonalSettings(state, personalSettings) {
      state.personalSettings = personalSettings;
    },
    setMail(state, mail) {
      state.userInfo.mail = mail;
    },
    setDefaultTasks(state, defaultTasks) {
      state.defaultTasks = defaultTasks;
    }
  },
  actions: {
    // handleUserMail({ commit }, { mail }) {
    //   return new Promise(resolve => {
    //     commit("setMail", mail);
    //     resolve(true);
    //   });
    // },
    // handleAvator({ commit }, { url }) {
    //   return new Promise(resolve => {
    //     commit("setAvator", url);
    //     resolve(true);
    //   });
    // },
    // handlePersonalSettings({ commit }, { store }) {
    //   return new Promise(resolve => {
    //     getPersonalSettings({ token: getToken(), store: store }).then(res => {
    //       if (res != false) {
    //         commit("setPersonalSettings", res.data.list);
    //         resolve(res);
    //       } else resolve(res);
    //     });
    //   });
    // },
    handleLogin({ commit }, { user, password, key }) {
      //userName = userName.trim()
      //return new Promise((resolve, reject) => {
      return new Promise(resolve => {
        loginApi({ user, password, key }).then(res => {
          //console.log(res);
          if (res != false) {
            commit("setToken", res.token);
            resolve(res);
          } else {
            resolve(false);
          }
        });
      });
    },
    handleUserInfo({ commit }, { store }) {
      return new Promise(resolve => {
        getUserInfo({ token: getToken(), store: store }).then(res => {
          if (res.code == 200) {
            //console.log(res.data)
            commit("setUserInfo", res.data);
            commit("setUserId", res.data.user_id);
            commit("setDefaultTasks", res.data.defaultTasks);
            resolve(res);
          } else {
            resolve(res);
          }
        });
      });
    },
    handleLogOut({ commit }, {store}) {
      return new Promise((resolve, reject) => {
        try {
          commit("setUserInfo", {
            avator: "",
            access: []
          });
          commit("setToken", "");
          commit("setUserId", "");

          //console.log('hoosliiii', store);
          store.state.signalr.osrCon.stop();

          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
    handleJWT({ commit }, { key, store }) {
      return new Promise(resolve => {
        jwt({ params: { key: key }, store: store }).then(res => {
          if (res != false) {
            commit("setToken", res.token);
            resolve(res);
          } else {
            resolve(false);
          }
        });
      });
    }
  }
};
