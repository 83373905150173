import { getMenuByRouter, createRoute, setSkin, getSkin } from "@/libs/util";
import { getGroupDefaultProjects } from "@/api/core";
import { request } from "@/api/all";

import routers from "@/router";

export default {
  state: {
    activeGroup: {},
    dynamicMenuLoaded: false,
    routes: [],
    loading: false,
    aside: {
      minimize: false,
      maximize: false,
      filemgr: false
    },
    responsiveLayout: {
      //ene deer field nemeh bol router-n const responsiveLayout utgand davhar setlej ogno
      backdrop: {
        class: "backdrop",
        show: false,
        onCloseEvent: 0,
        removeClassName: ""
      },
      level2: {
        show: false,
        removeClassName: ""
      },
      navbarNavShow: false
    },
    skin: {
      dark: ''//getSkin()
    },
    dialog: {
      show: false,
      fullScreen: false,
      persistent: false,
      title: "Санамж",
      titleColorBg: "",
      titleColoTxt: "",
      content: "",
      contenctCls: "",
      txtClose: "Хаах",
      txtOk: "",
      showOkBtn: false,
      yesOrNo: false,
      itemId: 0,
      itemObj: {},
      itemList: {
        title: "",
        list: []
      }
    },
    snackBar: {
      show: false,
      showClose: false,
      txt: ""
    },

    groupSettings: [],
    groupDefaultObjects: [],
    groupDefaultProjects: [],

    userSettings: [],

    pages: [],
    homePageId: 0,
    contentPageId: 0,
    localCompose: {
      isShow: false,

      title: "",
      body: "",

      userIds: [],
      taskIds: [],
      attachIds: [],
      attachIdsForward: [],
      isDraft: false,
      draftId: 0
    },
    userInfoId: null,
    mainLayoutConfig: {
      aside: false,
      /* header 
        0 bol bur bhgu
        1 bol dynamic-n header
        2 bol surgaltiin system
      */
      header: 0,
      footer: false,
      expanded: false
    },
    dynamicRoutes: [],
    isMobile: false,
    denyDesktop: false,
    mainReportData: {
      key: "",
      show: false,
      propObj: {}
    },
    activeForm: [],
    messenger:{
      open: false,
      wide: true,
      openId: null,
      activeTab: null,
      activeFlag: null,
    }
  },
  getters: {
    menuList: (state, getters, rootState) =>
      getMenuByRouter(routers.options.routes, rootState.user.access),
    // getDynamicMenuLoaded: () => state.dynamicMenuLoaded,
    // getRoutes: () => state.routes,
    dynamicMenu: (state, getters, rootState) =>
      getMenuByRouter(routers.options.routes, rootState.user.userInfo.access)
  },
  mutations: {
    setRoutes(state, routes) {
      state.routes = routes;
    },
    setDynamicRoutes(state, routes) {
      state.dynamicRoutes = routes;
    },
    setDarkSkin(state, isDark) {
      //console.log('isDark', isDark);
      state.skin.dark = isDark;
      setSkin(isDark);
    },
    setDynamicMenuLoaded(state, isLoaded) {
      state.dynamicMenuLoaded = isLoaded;
    },
    setLoading(state, showLoader) {
      //console.log('showLoader', showLoader);
      state.loading = showLoader;
    },
    setAside(state, obj) {
      state.aside.minimize = obj.minimize;
      state.aside.maximize = obj.maximize;
    },
    setDialog(state, obj) {
      state.dialog = obj;
    },
    setSnackbar(state, obj) {
      state.snackBar = obj;
    },
    setGroupSettings(state, settings) {
      state.groupSettings = settings;
    },
    setUserSettings(state, settings) {
      state.userSettings = settings;
    },
    setGroupDefaultProjects(state, data) {
      state.groupDefaultObjects = data.objects;
      state.groupDefaultProjects = data.projects;
    },
    setPages(state, pages) {
      state.pages = pages;
      let _home = pages.filter(x => x.st1 == "home");
      if (_home.length > 0) {
        state.homePageId = _home[0].id;
      }
      let _content = pages.filter(x => x.st1 == "content");
      if (_content.length > 0) {
        state.contentPageId = _content[0].id;
      }
    },
    setLocalMailCompose(state, obj) {
      state.localCompose.isShow = obj.isShow;

      state.localCompose.title = obj.title == undefined ? "" : obj.title;
      state.localCompose.body = obj.body == undefined ? "" : obj.body;

      state.localCompose.attachIds =
        obj.attachIds == undefined ? [] : obj.attachIds;
      state.localCompose.userIds = obj.userIds == undefined ? [] : obj.userIds;
      state.localCompose.taskIds = obj.taskIds == undefined ? [] : obj.taskIds;
      state.localCompose.attachIdsForward =
        obj.attachIdsForward == undefined ? [] : obj.attachIdsForward;

      state.localCompose.isDraft =
        obj.isDraft == undefined ? false : obj.isDraft;
      state.localCompose.draftId = obj.draftId == undefined ? 0 : obj.draftId;
    },
    setUserInfoModal(state, id) {
      state.userInfoId = id;
    },
    setMainReportDialog(state, data) {
      //console.log('data', data);
      state.mainReportData = data;
    },
    setActiveGroup(state, obj) {
      state.activeGroup = obj;
    },
    setMainLayoutConfig(state, config) {
      state.mainLayoutConfig = config;
    },
    setResponsiveLayoutConfig(state, config) {
      state.responsiveLayout = config;
    },
    setIsMobile(state, v) {
      state.isMobile = v;
    },
    setDenyDesktop(state, v) {
      state.denyDesktop = v;
    },

    setActiveForm(state, obj) {
      state.activeForm = obj;
    },
    setMessenger(state, obj) {
      //console.log('step 2', obj);
      state.messenger = obj;
    },
  },
  actions: {
    handleActiveGroup({ commit }, obj) {
      //console.log('handleActiveGroup', obj);
      return new Promise((resolve, reject) => {
        try {
          commit("setActiveGroup", obj);
          resolve(obj);
        } catch (error) {
          reject(error);
        }
      });
    },
    handleDarkSkin({ commit }, v) {
      return new Promise((resolve, reject) => {
        try {
          commit("setDarkSkin", v);
          resolve(v);
        } catch (error) {
          reject(error);
        }
      });
    },
    handleDynamicMenuLoaded({ commit }, { v }) {
      commit("setDynamicMenuLoaded", v);
    },
    handleResponsiveLayoutConfig({ commit }, v) {
      //console.log(v);
      commit("setResponsiveLayoutConfig", v);
    },
    handleIsMobile({ commit }, v) {
      //console.log(v);
      commit("setIsMobile", v);
    },
    handleDenyDesktop({ commit }, v) {
      //console.log(v);
      commit("setDenyDesktop", v);
    },
    // handleLevel1ResponsiveLayoutConfig({ commit },  document, className){

    // },
    handleLevel2ResponsiveLayoutConfig(
      { commit, state },
      { document, className }
    ) {
      let _obj = state.responsiveLayout;
      if (_obj.level2.show) {
        _obj.navbarNavShow = true;
        _obj.backdrop.show = true;
        _obj.backdrop.onCloseEvent = 1;
        _obj.backdrop.removeClassName = "navbar-nav-show";
        commit("setResponsiveLayoutConfig", _obj);
        document.body.classList.add("navbar-nav-show");
      } else {
        _obj.level2.show = true;
        _obj.level2.removeClassName = className;
        commit("setResponsiveLayoutConfig", _obj);
        document.body.classList.add(className);
      }
    },
    resetResponsiveLayoutConfig({ commit, state }, document) {
      //console.log(v);
      let _obj = state.responsiveLayout;
      if (_obj.backdrop.show) {
        if (
          _obj.backdrop.removeClassName != "" &&
          _obj.backdrop.removeClassName != undefined
        )
          document.body.classList.remove(_obj.backdrop.removeClassName);
      }

      if (_obj.level2.show) {
        if (
          _obj.level2.removeClassName != "" &&
          _obj.level2.removeClassName != undefined
        )
          document.body.classList.remove(_obj.level2.removeClassName);
      }

      commit("setResponsiveLayoutConfig", {
        backdrop: {
          class: "backdrop",
          show: false,
          onCloseEvent: 0,
          removeClassName: ""
        },
        level2: {
          show: false,
          removeClassName: ""
        },
        //appSideBar: false,
        navbarNavShow: false
        //appNavbarNavShow: false,
      });
    },
    handleMainLayoutConfig({ commit }, config) {
      commit("setMainLayoutConfig", config);
    },
    handleRoutes({ commit, state }, { router }) {
      return new Promise((resolve, reject) => {
        try {
          if (!state.dynamicMenuLoaded) {
            commit("setDynamicMenuLoaded", true);
            request({
              api: {
                method: "GET",
                url: "Config/RouteList"
              }
            })
              .then(res => {
                if (res != false) {
                  commit("setRoutes", res.data.routes);
                  let createdRoutes = [];
                  res.data.routes.forEach(menu => {
                    //console.log(menu, res.data.access)
                    let x = createRoute(menu, res.data.access);

                    if (x != null) {
                      router.addRoutes([x]);
                      createdRoutes.push(x);
                    }
                  });
                  commit("setDynamicRoutes", createdRoutes);
                  resolve(res.data.routes);
                } else {
                  commit("setDynamicMenuLoaded", false);
                  resolve([]);
                }
              })
              .catch(err => {
                reject(err);
              });
          } else {
            resolve(state.routes);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    handleLoading({ commit }, { isLoading }) {
      commit("setLoading", isLoading);
    },
    handleAside({ commit }, obj) {
      commit("setAside", obj);
    },
    handleDialog({ commit }, { obj }) {
      commit("setDialog", obj);
    },
    handleSnackbar({ commit }, { obj }) {
      commit("setSnackbar", obj);
    },

    handleGroupSettings({ commit }, { store }) {
      return new Promise((resolve, reject) => {
        try {
          request({
            api: { method: "GET", url: "Config/GetGroupSettings" },
            store: store
          })
            .then(res => {
              commit("setGroupSettings", res.data.settings);
              resolve(res.data.settings);
            })
            .catch(err => {
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    handleUsersettings({ commit }, { store }) {
      return new Promise((resolve, reject) => {
        try {
          request({
            api: { method: "GET", url: "Config/GetPersonalSettings" },
            store: store
          })
            .then(res => {
              //console.log(res);
              commit("setUserSettings", res.data.settings);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    handleGroupDefaultProjects({ commit }, { store }) {
      return new Promise((resolve, reject) => {
        try {
          getGroupDefaultProjects({
            store: store
          })
            .then(res => {
              commit("setGroupDefaultProjects", res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    handlePages({ commit }, { store }) {
      return new Promise((resolve, reject) => {
        try {
          request({
            api: { method: "POST", url: "TaskCore/GetCoreList" },
            data: {
              skip: 0,
              size: 200,
              ot: 356,
              model: {
                isPublic: true
              }
            },
            store: store
          })
            .then(res => {
              commit("setPages", res.data.tasks);
              resolve(res.data.tasks);
            })
            .catch(err => {
              reject(err);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    handleLocalMailCompose({ commit }, obj) {
      return new Promise((resolve, reject) => {
        try {
          //console.log(obj);
          commit("setLocalMailCompose", obj);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    handleUserInfoModal({ commit }, id) {
      return new Promise((resolve, reject) => {
        try {
          //console.log( id);
          commit("setUserInfoModal", id);
          resolve(id);
        } catch (error) {
          reject(error);
        }
      });
    },
    handleMainReportDialog({ commit }, data) {
      //console.log(data);
      return new Promise((resolve, reject) => {
        try {
          commit("setMainReportDialog", data);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    handleActiveForm({ commit }, obj) {
      return new Promise((resolve, reject) => {
        try {
          commit("setActiveForm", obj);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    handleMessenger({ commit }, obj) {
      return new Promise((resolve, reject) => {
        try {
          //console.log('step 1');
          commit("setMessenger", obj);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};
