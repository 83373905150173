import { createStore } from 'vuex'

import app from "./module/app";
import user from "./module/user";
import signalr from "./module/signalr";

export default createStore({
  state: {
    loading: false,
    key: 'FN5PtdEgU0mKXK42q2KSog'
  },
  mutations: {
    setLoading(state, showLoader) {
      state.loading = showLoader;
    },
  },
  actions: {
    handleLoading({
      commit
    }, {
      isLoading
    }) {
      commit("setLoading", isLoading);
    },
  },
  modules: {
    app,
    user,
    signalr
  }
})
