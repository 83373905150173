import axios from "@/libs/api.request";
import { getToken } from "@/libs/util";
import { dynamicDispatcher } from "@/libs/util";

export const loginApi = ({ user, password, key }) => {
  return axios.request({
    url: "Core/LoginApi",
    params: {
      user: user,
      password: password,
      key: key
    },
    method: "post"
  });
};

export const requestApi = ({ api, method, data, params }) => {
  //console.log(api);
  return axios.request({
    //url: api != undefined ? api : "Core/RequestApi",
    url: "Core/RequestApi",
    //url: "penaltyClientCoreConnector",
    params: params,
    data: {
      api: api,
      method: method,
      token: getToken(),
      data: data
    },
    method: "post"
  });
};

export const getUserInfo = ({ data, params, store }) => {
  return new Promise(resolve => {
    requestApi({
      api: "User/get_info",
      method: "get",
      params: params,
      data: data
    }).then(res => {
      if (res != false) {
        let rs = dynamicDispatcher(res, store);
        if (rs != false) {
          resolve(rs);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  });
};

export const getGroupDefaultProjects = ({ store }) => {
  return new Promise(resolve => {
    requestApi({
      api: "Config/GetDefaultProjects",
      method: "get"
    }).then(res => {
      if (res != false) {
        let rs = dynamicDispatcher(res, store);
        if (rs != false) {
          resolve(rs);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  });
};