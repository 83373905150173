//import Vue from "vue";

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//import './assets/scss/dashforge.scss';
// import './assets/css/dashforge.css';
import './assets/css/style.css';

import { formatMoney } from "@/libs/tools";

// const moment = require('moment')
// require('moment/locale/mn')

// import Vue from 'vue'
// import VueMoment from 'vue-moment'

// Vue.use(require('vue-moment'), {
//     VueMoment
// })
// Vue.filter("formatMoney", function(n, c, d, t) {
//     return formatMoney(n, c, d, t);
// });

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// createApp(App).use(store).use(router).mount('#app')

import VueHtmlToPaper from "vue-html-to-paper";
const printOptions = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
      "https://res.gosmart.mn/da/assets/css/dashforge.css",
    ],
    timeout: 1000,
    autoClose: true,
    windowTitle: window.document.title,
  };
  
  
//import VueAnalytics from 'vue-analytics'
import VueGtag from "vue-gtag"

const app = createApp(App)
app.use(VueGtag, {
  config:{id: 'G-2QEN7XW0J4'}
})


app.use(VueHtmlToPaper, printOptions);
app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')