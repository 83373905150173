export const forEach = (arr, fn) => {
    if (!arr.length || !fn) return;
    let i = -1;
    let len = arr.length;
    while (++i < len) {
      let item = arr[i];
      fn(item, i, arr);
    }
  };
  
  /**
   * @param {Array} arr1
   * @param {Array} arr2
   * @description
   */
  export const getIntersection = (arr1, arr2) => {
    let len = Math.min(arr1.length, arr2.length);
    let i = -1;
    let res = [];
    while (++i < len) {
      const item = arr2[i];
      if (arr1.indexOf(item) > -1) res.push(item);
    }
    return res;
  };
  
  /**
   * @param {Array} arr1
   * @param {Array} arr2
   * @description
   */
  export const getUnion = (arr1, arr2) => {
    return Array.from(new Set([...arr1, ...arr2]));
  };
  
  /**
   * @param {Array} target
   * @param {Array} arr
   * @description
   */
  export const hasOneOf = (targetarr, arr) => {
    return targetarr.some(_ => arr.indexOf(_) > -1);
  };
  
  /**
   * @param {String|Number} value
   * @param {*} validList
   */
  export function oneOf(value, validList) {
    for (let i = 0; i < validList.length; i++) {
      if (value === validList[i]) {
        return true;
      }
    }
    return false;
  }
  
  export function isActiveNow(d1, d2, now) {
    var _now= new Date(now);
    var _d1= new Date(d1);
    var _d2= new Date(d2);
    
    if(_d1<=_now && _d2>=_now){
      return true;
    }else{
      return false;
    }
  }
  
   export function getSize(size, decimals){
    //return size>1024 ? ((size/1024)/1024).toFixed(2)+'MB' : (size/1024).toFixed(2)+'KB';
    if(size == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
   }
  
   export function getSizeHtml(size, decimals){
    //return size>1024 ? ((size/1024)/1024).toFixed(2)+'MB' : (size/1024).toFixed(2)+'KB';
    if(size == 0) return '0 <sup>Bytes</sup>';
    var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' <sup>' + sizes[i]+'</sup>';
   }
  
   export const getFileMode = (extension) => {
    //console.log(extension);
    let rv = '';
    switch (extension.toLowerCase()) {
      case 'mp4': rv = 'video'; break;
      case 'mp3': rv = 'audio'; break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
      case 'gif':
        rv = 'image'; break;    
      case 'xls':
      case 'txt':
      case 'rtf':
      case 'xlsx':    
      case 'ppt':
      case 'pptx':
      case 'doc':
      case 'docx':
      case 'csv':    
      //case 'pdf':
      //case 'pdf':
        rv = 'document'; break;
       //rv = 'pdf'; break;
      //case 'eml': rv = 'eml'; break;
  
      case 'pdf':
        //rv = 'pdf'; break;
        rv = 'pdfObject'; break;
  
      default: rv = 'default'; break;
    }
    return rv;
  }
  
  export const getFileIcon = (ex) => {
    let rv = '';
    switch (ex.toLowerCase()) {
      case 'mp4': rv = 'fas fa-play'; break;
      case 'mp3': rv = 'audio'; break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
        rv = 'image'; break;    
      case 'xls':
      case 'xlsx':
      case 'csv': 
        rv = 'excel'; break;    
      case 'txt':
      case 'rtf':
      case 'ppt':
      case 'pptx':
        rv = 'powerpoint'; break;
      case 'doc':
      case 'docx':
        rv = 'word'; break;
      case 'pdf':
        rv = 'pdf'; break;     
  
      default: rv = 'default'; break;
    }
    return rv;
  }
  
  export const alphabetize = (dividend) => {
    dividend=dividend+1;
    var columnName = "";
    var modulo;
  
    while (dividend > 0)
    {
        modulo = (dividend - 1) % 26;                
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = parseInt(((dividend - modulo) / 26), 10);
    }
  
    return columnName;
  }
  
  export const romanize = (num) => {
    var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 },
      roman = '', i;
  
      for (i in lookup) {
          while (num >= lookup[i]) {
              roman += i;
              num -= lookup[i];
          }
      }
      return roman;
  }
  
  export const formatAllDate = (v) => {
    let date = new Date(v);
    return date.getFullYear() + "-" + 
    (parseInt(date.getMonth() + 1)>=10?date.getMonth() + 1: '0'+(date.getMonth() + 1))
     + "-" + 
     (parseInt(date.getDate())>=10?date.getDate():'0'+date.getDate())
     + " " + 
     (parseInt(date.getHours())>=10?date.getHours():'0'+date.getHours())
     + ":" + 
     (parseInt(date.getMinutes())>=10?date.getMinutes():'0'+date.getMinutes())
     + ":" + 
     (parseInt(date.getSeconds())>=10?date.getSeconds():'0'+date.getSeconds());
  }
  
  export const formatDateYMDHM = (v) => {
    if(v==null) return '';
    let date = new Date(v);
    return date.getFullYear() + "-" + 
    ((date.getMonth() + 1)>=10? (date.getMonth() + 1): ('0'+(date.getMonth() + 1)) ) + "-" + 
    (date.getDate()>=10 ? date.getDate(): '0'+date.getDate() ) + " " + 
    (date.getHours()>=10 ? date.getHours() : '0'+date.getHours() ) + ":" + 
    (date.getMinutes()>=10 ? date.getMinutes() : '0'+date.getMinutes() );
  }
  
  export const formatMoney = (n, c, d, t) => {
    let s="";
    let j=0;
    let i="";  
    c = isNaN((c = Math.abs(c))) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      j = (j = i.length) > 3 ? j % 3 : 0;
  
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
        : "")
    );
  }