import Cookies from "js-cookie";
import config from "@/config";

import { forEach, hasOneOf } from '@/libs/tools'

// import {Notification} from "element-ui";

// import "element-ui/lib/theme-chalk/index.css";

export const REDIRECTURL_KEY = "reurl";

var CryptoJS = require("crypto-js");

export const TOKEN_KEY = "token";

export const setToken = token => {
  Cookies.set(TOKEN_KEY, token, { expires: config.cookieExpires || 1 });
};


export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY);
  if (token) return token;
  else return false;
};

export const setRedirectUrl = path => {
  Cookies.set(REDIRECTURL_KEY, path, { expires: config.cookieExpires || 1 });
};

export const localSave = (key, value) => {
  localStorage.setItem(key, value);
};

export const localRead = key => {
  return localStorage.getItem(key) || "";
};

export const manageMainLayoutConfig = (toPath, store, clear, fromLogin) =>{
  let _obj = store.state.app.mainLayoutConfig;  
  if(clear!=undefined && clear){
    if(store.state.app.activeGroup.publicPage!=undefined && store.state.app.activeGroup.publicPage.has){
      
      if(toPath.name =='landingPage') {
        _obj.header=0;
        _obj.aside=false;
        _obj.footer=false;
      }
      else{
        if(toPath.name=='login' || fromLogin){        
          _obj.header=-1;
          _obj.aside=false;
          _obj.footer=true;
        }else{
          _obj.header=0;
          _obj.aside=false;
          _obj.footer=false;
        }    
      }  
    }else{
      _obj.header=-1;
      _obj.aside=false;
      _obj.footer=true;
    }
  }else{
    if(store.state.user.userId!='')
    {
      if(toPath.name!=''){
        if(hasOneOf([toPath.name], config.lmsPages)){
          _obj.header=2;  
          _obj.aside=false;
          _obj.footer=false;
        }else if(toPath.name=='home'){
          _obj.header=-1;
          _obj.aside=false;
          _obj.footer=false;
        }
        else{
          _obj.header=1;  
          _obj.aside=true;
          _obj.footer=false;
        }
      }else{
        _obj.header=0;
        _obj.aside=false;
        _obj.footer=false;
      }
    }
    else{
      _obj.footer=false;
      _obj.header=0;
      _obj.aside=false;
    }
  }
  store.dispatch('handleMainLayoutConfig', _obj);
}

export const dynamicDispatcher = (res) => {

  var Pass = CryptoJS.enc.Utf8.parse(res.data.substring(0, 22));
  var encryptData = res.data.substring(22, res.data.length);

  try {
    var iv = CryptoJS.enc.Hex.parse("e84ad660c4721ae0e84ad660c4721ae0");
    var Salt = CryptoJS.enc.Utf8.parse("u2m9d2dfds1xS!2");
    var key128Bits1000Iterations = CryptoJS.PBKDF2(
      Pass.toString(CryptoJS.enc.Utf8),
      Salt,
      { keySize: 128 / 32, iterations: 1000 }
    );
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(encryptData)
    });

    var decrypted = CryptoJS.AES.decrypt(
      cipherParams,
      key128Bits1000Iterations,
      { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 }
    );
    let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    //console.log('decrypted', data);
    if (data.code !== 200) {
      if(data.msg != undefined && data.msg != ''){
        alert(data.msg);
      }

      if (data.code === 401 && data.id!=undefined) {
        Cookies.remove(TOKEN_KEY)
        window.location.href = '/#/'+data.id
      } else if (data.code === 401) {
        Cookies.remove(TOKEN_KEY)
        window.location.href = '/'
      }
      return false;
    } else return data;
  } catch (ex) {

    // Notification.info({
    //   message: ex,
    //   title: "Мэдэгдэл",
    //   duration: 10000,          
    //   type: "warning",
    //   position: 'top-right',
    //   offset: 50
    // });

    return false;
  }
};

/**
 * @param {*} access 
 * @param {*} route 
 */
const hasAccess = (access, route) => {
  //console.log("", route);
  if (route.meta && route.meta.access!=undefined && route.meta.access.length!=0 && route.meta.access)
  {
     return hasOneOf(access, route.meta.access)
  }
  else return true
}

export const findRouteNameByPath = (routes, path) => {
  let findName = "";
  //console.log('findRouteNameByPath',path);
  routes.forEach(rt => {
    //console.log('rt', rt);
    if (rt.path == path) findName = rt;
    else if(rt.children!=undefined && rt.children.length>0) {
      rt.children.forEach(sbrt => {
        // if(sbrt.path.indexOf(':ot')>-1)
        // {
        //   sbrt.path = sbrt.path.replace(':ot', sbrt.meta.ot);
        //   sbrt.params={ot:sbrt.meta.ot};
        // }else
        if (sbrt.path.split("/").length > 3 && path.split("/").length > 3) {
          let sbrtarr = sbrt.path.split("/");
          let ptarr = path.split("/");
          if (sbrtarr[1] == ptarr[1] && sbrtarr[2] == ptarr[2]) {
            findName = sbrt;
            let rawParams = "{";
            for (let i = 0; i < sbrtarr.length; i++) {
              if (i > 2) {
                rawParams +=
                  '"' +
                  sbrtarr[i].replace(":", "") +
                  '"' +
                  ":" +
                  '"' +
                  ptarr[i] +
                  '"';
                if (i + 1 != sbrtarr.length) rawParams += ",";
              }
            }
            rawParams += "}";
            findName.params = JSON.parse(rawParams);
          }
        }

        if (sbrt.path == path) findName = sbrt;
      });
    }
  });
  return findName;
};